<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop='title' label='轮播图名称'>
        <el-input v-model="form.data.title" placeholder="请输入轮播图名称" maxlength="20" clearable></el-input>
      </el-form-item>
      <el-form-item prop='image' label='轮播图图片'>
        <el-upload
          class="avatar-uploader"
          action=""
          :headers="{ token: token }"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="httpRequest">
          <img v-if="form.data.image" :src="form.data.image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item prop='show' label='展示位置'>
        <el-radio-group v-model="form.data.show">
          <el-radio :label="1">需求端</el-radio>
          <el-radio :label="2">师傅端</el-radio>
          <el-radio :label="3">全部</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 类型  1:链接 2:文本 3:企业 4:岗位 5:代理 -->
      <el-form-item prop='type' label='类型'>
        <el-select v-model="form.data.type">
          <el-option label="文本" :value="2"></el-option>
          <el-option label="代理" :value="5"></el-option>
        </el-select>
      </el-form-item>
      <!-- 文本类型才显示 -->
      <el-form-item prop='jump_url' label='广告链接' v-if="form.data.type == 2">
        <el-input
          type="textarea"
          placeholder="请输入广告链接"
          v-model="form.data.jump_url"
          maxlength="200"
          show-word-limit
          :autosize="{ minRows: 2, maxRows: 4}"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>

    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img v-if="dialogVisible" width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'BannerEdit',
    data() {
      return {
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            title: '',
            image: '',
            status: 1,
            jump_url: '',
            type: 2,  //类型  1:链接 2:文本 3:企业 4:岗位 5:代理
            show: 1,
          },
          rules: {
            title: [{ required: true, message:'必填项', trigger: 'change' }],
            image: [{ required: true, message: '必填项', trigger: 'change' }],
            show: [{ required: true, message: '必填项', trigger: 'change' }],
          }
        },
        dialogVisible: false,
        dialogImageUrl: [],
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true;
        this.$http.get('/government/banner/info', {params:{id: row.id} }).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data)
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let apiUrl = !this.isChange ? '/government/banner/add' : '/government/banner/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.form.data.image = res.data.all_path_url;
          this.$refs.elFormDom.clearValidate();
        })
      }
    }
  }
</script>