var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-view"},[_c('div',{staticClass:"section shadow filter__wrap"},[_c('el-form',{ref:"elFormDom",attrs:{"inline":"","model":_vm.table.params,"size":"mini"}},[_c('el-form-item',{attrs:{"label":"轮播图名称:","prop":"title"}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入轮播图名称"},model:{value:(_vm.table.params.title),callback:function ($$v) {_vm.$set(_vm.table.params, "title", $$v)},expression:"table.params.title"}})],1),_c('el-form-item',{attrs:{"label":"展示用户:","prop":"show"}},[_c('el-select',{model:{value:(_vm.table.params.show),callback:function ($$v) {_vm.$set(_vm.table.params, "show", $$v)},expression:"table.params.show"}},[_c('el-option',{attrs:{"label":"全部","value":3}}),_c('el-option',{attrs:{"label":"需求端","value":1}}),_c('el-option',{attrs:{"label":"师傅端","value":2}})],1)],1),_c('el-form-item',[_c('el-button',{staticClass:"theneBtn",attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.onSearch}},[_vm._v("查询 ")]),_c('el-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1),_c('el-button',{attrs:{"size":"mini","type":"primary","plain":"","icon":"el-icon-plus"},on:{"click":_vm.showEdit}},[_vm._v("添加")])],1),_c('VTable',{attrs:{"has-pagionation":"","title":"","addText":"添加","field":_vm.field,"loading":_vm.table.loading,"data":_vm.table.data,"page":_vm.table.params.page,"pageSize":_vm.table.params.count,"total":_vm.table.total,"hasPagionation":true,"default-expand-all":true,"showOverflowTooltip":true,"showSelect":false},on:{"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":row.image,"fit":"cover","preview-src-list":[row.image]}})]}},{key:"show",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.toStr({1:'需求端',2:'师傅端',3:'全部'}, row.show)))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":1,"inactive-value":2},on:{"change":function($event){return _vm.handleStatus(row)}},model:{value:(row.status),callback:function ($$v) {_vm.$set(row, "status", $$v)},expression:"row.status"}})]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","icon":"el-icon-edit"},on:{"click":function($event){return _vm.showEdit(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text","icon":"el-icon-delete"},on:{"click":function($event){return _vm.remove(row)}}},[_vm._v("删除")])]}}])}),_c('edit',{ref:"edit",on:{"refresh":_vm.getTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }